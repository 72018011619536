.search-container{
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  background-color: rgb(240,240,240);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.search-container-large{
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  background-color: rgb(240,240,240);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  //padding: 4px 4px !important;
  //height: 40px !important;
  //background: red;
  //border: 1px solid red;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  //height: 40px;
  background: white;
  border-color:  red;
}



.searchBar{
  min-width:500px ;
  //width: 100%;
  height: 55px;
  margin-top: 12px;
  background: white;
  border-radius: 5px;
  border: 1px solid rgb(196,196,196);
}



.location-dropdown-container{
  //max-width:800px ;
  margin-top: 10px;
  cursor: pointer;
  //width: 100%;
  //max-width: 500px;
  //background: white;
  display: flex;
  align-items: start;
  justify-content: start;
  height: 55px;
  border-color:rgb(95, 123, 128) ;
  border-radius: 5px;
}

.Dropdown-root{
  width: 250px;
}

.search-input{
  padding-left: 10px;
  width: 80%;
  height: 47px;
  margin: 1px;
  border: none;
}

.location-dropdown-button {
  width: 160px;
  margin-top: 10px;
  border: 1px solid #dddddd;
}

.location-dropdown{
  max-width: 250px !important;
  max-height: 700px;
  min-width: 50px;
  border: 1px solid black;
  padding: 0 !important;
}

.dropdown-button{
  padding: 5px;
}

.city-text{
  width: 80px;
}

.city-menu-item{
  height: 40px;
  margin-top: auto;
  margin-bottom: 0px;
  padding: 0 10px !important;
}

.city-menu-item:hover{
  background-color: #dddddd;
}

.Dropdown-root {
  position: relative;
}

.Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  color: #333;
  max-width: 300px;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
}

.Dropdown-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.Dropdown-menu {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group > .Dropdown-title{
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.Dropdown-option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.Dropdown-option:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-option.is-selected {
  background-color: #f2f9fc;
  color: #333;
}

.Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .search-container-large{
    display: none;
  }
  .search-container{
    display: flex
  }
  .searchBar {
    min-width: 300px;
  }

  .location-dropdown-container{
    //width: 100%;
    max-width: 500px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 768px) {
  .search-container-large{
    display: none;
  }
  .search-container{
    display: flex;
  }
  .searchBar {
    min-width: 320px;

  }
  .location-dropdown-container{
    //width: 100%;
    max-width: 500px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .search-container-large{
    display: flex;
  }
  .search-container{
    display: none;
  }
  .searchBar {
    margin-left: 5px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .search-container-large{
    display: flex;
  }
  .search-container{
    display: none;
  }
  .searchBar {
    margin-left: 5px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .search-container-large{
    display: flex;
  }
  .search-container{
    display: none;
  }
  .searchBar {
    margin-left: 5px;
  }
}


