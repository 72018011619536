.add-button-layout{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid green;
}

.file-name{
  font-family: Nunito-Regular,sans-serif;
  font-size: 15px;
  width: 150px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2d3142;
  margin-bottom: 0;
}

.upload-media-layout{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.upload-media-left-layout{
  padding: 10px;
  border-right: 1px solid #dddddd;
}
.upload-media-right-layout{
  padding: 10px;
  height: 100%;
}

.add-files-layout{
  width: 160px;
  height: 150px;
  border-radius: 10px;
  background-color: rgb(250,250,250);
  border: 1px solid #dddddd;
}

.choose-to-upload{
  color: rgb(88,85,84);
  font-size: 16px;
  text-align: center;
  font-family: Nunito-Bold,sans-serif;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .upload-media-layout{
   flex-direction: column !important;
  }
  .upload-media-left-layout{
    width: 100%;
  }
  .upload-media-right-layout{
    width: 100%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 799px) {
  .upload-media-layout{
    flex-direction: column !important;
  }
  .upload-media-left-layout{
    width: 100%;
  }
  .upload-media-right-layout{
    width: 100%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 800px) and (max-width: 991.98px) {
  .upload-media-layout{
    flex-direction: column !important;
  }
  .upload-media-left-layout{
    width: 70%;
  }
  .upload-media-right-layout{
    width: 70%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .upload-media-layout{
    flex-direction: column !important;
  }
  .upload-media-left-layout{
    width: 70%;
  }
  .upload-media-right-layout{
    width: 70%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .upload-media-layout{
    flex-direction: column !important;
  }
  .upload-media-left-layout{
    width: 70%;
  }
  .upload-media-right-layout{
    width: 70%;
  }
}


