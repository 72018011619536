
.settings{
    padding-top:80px;
    text-align: left;
    margin-bottom: 10px;
}

.settings-details{
    border:1px solid #d8d6d9;
    border-radius: 5px;
    height: auto;
}

.listWrapper{
    border-top:1px solid #d8d6d9;
    padding:20px 0px;
}

.editIcon{
    color:#307f9c;
    cursor:pointer;
}
.toggleButton{
    border :1px solid rgb(247, 156, 140);
    border-radius:5px;
    .active{
        background-color: rgb(247, 156, 140);
        color:#4e4a4a;
    }
}
