.wrapper{
     padding-top: 50px;
    height: calc(100vh - 150px);
}

.aboutUsText{
    font-weight: bold;
    font-size: 20px;
    font-family: Nunito, sans-serif;
    color: rgb(224, 115, 114);
    margin-top: 20px;
}

.aboutUsDesc{
    align-items: center;
    text-align: left;
    font-size: 20px;
    font-family: Nunito, sans-serif;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
}
.aboutUsAsst{
    font-size: 20px;
    font-family: Nunito, sans-serif;
    color: black;
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
}

.active{
    height: 50px;
    margin: 0px;
    text-align: center;
    /*color: white;*/
    align-items: center;
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
}
