.past-detail-container{
  height: 100%;
  width: 100%;
  display: flex;
  max-width: 1200px;
  border: 1px solid lightgray;
  font-family:sans-serif;
  padding: 10px;
  flex-direction: row;
  margin-bottom: 10px;
}

.value{
  font-weight: normal;
}

.key{
  font-family: Nunito,sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: black;
}

.post-detail {
  width: 50%;
  margin-left: 20px;
  height: 100%;
  //border: 1px solid #dddddd;
  min-height: 70vh;
}

.description{
  //font-family: Signika-Regular,sans-serif;
}

.active{
  //background-color: rgb(230,236,238);
  font-size: 16px;
  //margin-bottom: 5px;
  align-items: center;
  padding-left: 0;
}

.activeM{
  font-size: 16px;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  //height: 30px;
  padding-left: 0 !important;
  flex-direction: row;
}

.active p{
  margin-bottom: 5px;
  font-size: 17px;
}

.notActive{
  width: 320px;
  padding: 10px;
  display: flex;
  background-color: rgb(230,236,238);
  margin: 4px;
  align-items: center;
  justify-content: space-between;
}

.drop-down-contentM{
  display: flex;
  text-decoration: none;
  //background: rgb(224, 115, 114);
  color: #0083ca;
  margin-bottom: 10px;
  width: 130px !important;
  height: 40px;
  align-items: start;
  flex-direction: row;
}

.notActive p{
  margin-bottom: 0;
}

.post-carousal{
  width: 50%;
  height: 70vh;

  min-height: 70vh;
}

.message-subject{
  width: 100%;
}

.ad-desc{
  font-size: 17px !important;
}

.more-details{
  //font-family: Signika-Regular,sans-serif;
}

textarea{
  width: 100%;
  padding: 10px;
  font-weight: normal;
  line-break: auto;
  font-size: 15px;
  line-height: 200%;
  color: black;
  height: 150px;
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {

  .past-detail-container{
    flex-direction: column;
    padding: 0;
  }

  .post-detail {
    width: 100vw;
    margin: 0;
    min-height: 70vh;
    height: 100%;
  }

  .post-carousal{
    width: 100vw;
    height: 50vh;
    min-height: 70vh;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 799px) {

  .past-detail-container{
    flex-direction: column;
    padding: 0;
  }

  .post-detail {
    width: 100vw;
    height: 100%;
    margin: 0;
    min-height: 70vh;
  }

  .post-carousal{
    width: 100vw;
    height: 50vh;
    min-height: 70vh;
  }


}



.hidden {

}

svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

button, .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

button:hover, .button:hover {
  border-color: #cdd;
}

.share-button, .copy-link {
  padding-left: 30px;
  padding-right: 30px;
  background: gray;
}

.share-button, .share-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  display: none;
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
}

.share-dialog.is-open {
  display: block;
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 800px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}







