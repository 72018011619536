.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  background-color: red;
  position: fixed;
}

.toast-feedback-container {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  min-width: 250px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 3px rgb(95, 123, 128) solid;
}

.success-icon {
  width: 20px;
  margin: 0px 10px;
  height: 20px;
}

.custom-toast {
  &-container {
    background-color: #d5dedf;
    padding: 10px;
    min-width: 320px;
  }

  &-header {
    padding-bottom: 15px;
    width: 100%;
    background-color: transparent;

    &-text {
      font-family: Nunito-Bold, sans-serif;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      margin-bottom: 0 ;
      letter-spacing: normal;
      text-align: left;
      //color: $bluish-purple
    }
  }

  &-content {
    padding-bottom: 10px;

    &-heading {
      margin-bottom: 0 ;
      font-family: Nunito-Bold,sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      margin-right: auto;
      color: #000000;
    }

    &-message {
      margin-bottom: 0 ;
      font-family: Nunito-Bold,sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      //height: 50px;
      letter-spacing: normal;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
      text-overflow: ellipsis;
      margin-right: auto;
      color: #000000;
    }

    &-subject {
      margin-bottom: 0 ;
      font-family: Nunito-Bold,sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      height: 40px;
      overflow: hidden;
      max-width: 230px;
      margin-left: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: normal;
      text-align: left;
      margin-right: auto;
      color: #000000;
    }
  }

  &-time {
    width: 28px;
    height: 19px;
    font-family: Nunito-Bold,sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    margin-bottom: 0 ;
    letter-spacing: normal;
    text-align: right;
    color:#dddddd;
  }

  &-close {
    width: 15px;
    height: 24px;
    margin-left: 20px;
    color: #000000;
  }
}



