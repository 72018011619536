@import "../../../src/styles/variables";

.chat-right-card {

  &-container {
    width: 100%;
    padding-right: 23px;
    margin-bottom: 25px;
  }

  &-time-label {
    font-family: Nunito-Regular,sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
    color: $instruction-text;
  }

  &-message-container {
    max-width: 340px;
    padding: 12px;
    margin-left: 12px;
    border-radius: 10px 10px 0 10px;
    background-color: $bluish-purple;
  }

  &-content {
    margin-left: 25px;
  }

  &-message {
    font-family: Nunito-Regular,sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    white-space: pre-wrap;
    letter-spacing: normal;
    text-align: left;
    color: white;
    margin: 0;
  }

  &-profile {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}



