$primaryColor: #bada55;
$secondaryColor: #ff006c;
$white: #ffffff;

// Hex colors as written in the wire frames
$primary-color: #f02a75;
$instruction-text: #55595c;
$ghost-and-disabled-text: #7e868d;
$off_White: #efeeed;
$collection-expanded-container-color: #d0d2d3;
$shared-collection-color: #01be8b;
$favorites-star-color: #ff6600;
$unread-notification-bg: #6f9ca6;
$places-expanded-color: #198de2;
$body-text: #2d3142;
$very-light-pink: #dddddd;
$strong-pink: #ff096c;
$bluish-purple: #6a3ce8;
$white: #ffffff;
$black: #000000;
$private-collection-color: #6a3ce8;
$shared-collection-color: #01be8b;
$instruction-text: #55595c;

$width-xl: 1200;
$width-lg: 992;
$width-md: 768;
$width-sm: 576;
$width-xs: 575;
