.ad-input{
    width: 300px;
    padding: 0;
    margin: 0;
}

.createAdWrapper{
    background: rgba(220,220,220,0.3);
    min-height: 100vh ;
    height: 100%;
}

.ad-text-area{
    width: 600px;
    padding: 0;
    margin: 0;
}

.input-label{
    color: grey;
}

.create-ad-form {
    max-width: 800px;
    width: 100%;
    border: 1px rgb(60, 52, 52) solid;
}

.ad-input-group{
    display: flex;
    flex-direction: row;
}

@media (max-width: 320px) {
    .ad-input-group{
        flex-direction: column;
    }
    .ad-text-area{
        width: 300px !important;
    }
}

@media (min-width: 321px) and (max-width: 799px) {
    .ad-input-group{
        flex-direction: column;
    }
    .ad-text-area{
        width: 320px !important;
    }
}

@media (min-width: 800px) and (max-width: 991.98px) {
    .ad-input-group{
        flex-direction: row;
    }
    .ad-text-area{
        width: 600px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .ad-input-group{
        flex-direction: row;
    }
    .ad-text-area{
        width: 600px;
    }
}

@media (min-width: 1200px) {
    .ad-input-group{
        flex-direction: row;
    }
    .ad-text-area{
        width: 600px;
    }
}

