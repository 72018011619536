@import "../../styles/variables";

.ad-new-card{
  margin-bottom: 30px;
  height: 340px;
  width: 100%;
  max-width: 300px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 10px !important;
  padding: 0 0 10px;
  border: 1px #dddddd solid;
  transition: transform .2s; /* Animation */
}

.ad-new-card:hover{
  transform: scale(1.01);
}

.ad-name{
  font-family: Nunito-Bold,sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 18px;
  width: 240px;
  //clear: both;
  //display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  //text-align: center;
  color: $body-text;
  margin-bottom: 0;
}

.ad-image{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  max-width: 100%;
  width: 300px;
  object-fit: contain;
  height: 150px;
}

.ad-price {
  //font-family: Nunito-Bold,sans-serif;
  //font-weight: bold;
  //font-stretch: normal;
  //font-style: normal;
  //letter-spacing: normal;
  //text-align: left;
  //display: inline-block;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  //overflow: hidden;
  color: #E77179;
  font-weight: bold;
  margin-bottom: 0;
}

.ad-title {
  font-family: Nunito-Bold,sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  text-align: center;
  color: $body-text;
  margin-bottom: 0;
  background: #6a3ce8;
}

.ad-description{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: $body-text;
  margin-bottom: 0;
}

.ad-location{
  font-family: Nunito-Regular,sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $body-text;
  margin-bottom: 0;
}

.ad-date{
  font-family: Nunito-Regular,sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $body-text;
}

.viewMoreButton {
  background: #ECAF44;
  color: white;
  height: 30px;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.action-layout {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  height: 45px;
  //background-color: #E9BD43;
  //background-color: #FFB600;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}




