.header-container{
  background-color: rgb(19,25,33);
  width: 100%;
  height: 56px;
}

.header-text{
  color: white;
  margin-bottom: 0;
}
