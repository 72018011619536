.carousel-wrapper{
  min-height: 100%;
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: center;
}

.leftArrow{
  cursor: pointer;
  left:0;
}

.rightArrow {
  cursor: pointer;
  right: 0;
}


.nav-icons {
  width: 35px !important;
  padding: 4px;
  border-radius: 50%;
  background-color:#dddddd;
  height: 35px  !important;
}


.carousal-image-container{
  min-width: calc(100% - 35px - 35px - 32px - 20px);
  max-width: calc(100% - 35px - 35px - 32px - 20px);
  height: 100%;
}


.carousal-image {
  max-width: calc(100%);
  max-height: 600px;
  object-fit: cover;

}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .carousal-image-container{
    min-width: calc(100% - 35px - 35px - 32px - 20px);
    max-width: calc(100% - 35px - 35px - 32px - 20px);
    height: 30vh;
  }
  .carousal-image {
    max-width: calc(100% );
    max-height: 30vh;
    padding: 5px;
    object-fit: contain;

  }
  .carousel-wrapper{
    padding: 0;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 799px) {
  .carousal-image-container{
    min-width: calc(100% - 35px - 35px);
    max-width: calc(100% - 35px - 35px);
    height: 30vh;
  }
  .carousal-image {
    max-width: calc(100%);
    max-height: 30vh;
    object-fit: contain;

  }
  .carousel-wrapper{
    padding: 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 800px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}







