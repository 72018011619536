.footer {
//position: fixed;
//left: 0;
    padding: 10px;
//bottom: 0;
    width: 100%;
    background-color: #ECAF44;
    color: white;
    text-align: center;
    margin-top: 10px;
}

.footer-container{
    background-color: rgb(25,27,33);
    width: 100%;
}

.footer-text{
    color: black;
    margin-bottom: 0;
}

.navLink{
    height: 50px;
    margin:0px;
    padding: 20px;
    text-align: center;
    color: white;
    align-items: center;
    display: flex;

}


.link{
    /*padding: 10px;*/
    /*margin-top: -10px;*/
}


.navLink:hover{
    color: white;
}
.copyright{
    color: white;
}

.contactWithUs{
    display: flex;
    width: 250px;
    padding: 20px 50px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 5px;
    justify-content: space-between;
}

.contactWithUs a{
    color: white;
}

.contactWithUs a:hover{
    color: white;
}
