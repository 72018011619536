body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"),
  url(./Assets/Fonts/Nunito-Regular.ttf) format("truetype");
}


@font-face {
  font-family: "Signika-Bold";
  src: local("Signika-Bold"),
  url(./Assets/Fonts/Signika-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Signika-Regular";
  src: local("Signika-Regular"),
  url(./Assets/Fonts/Signika-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"), url(./Assets/Fonts/Nunito-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-SemiBold";
  src: local("Nunito-SemiBold"),
  url(./Assets/Fonts/Nunito-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Nunito-ExtraBold";
  src: local("Nunito-ExtraBold"),
  url(./Assets/Fonts/Nunito-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Merriweather-Bold";
  src: local("Merriweather-Bold"),
  url(./Assets/Fonts/Merriweather-Bold.ttf) format("truetype");
}
