.wrapper{
    padding-top: 50px;
    height: calc(100vh - 150px);
}

.contactUsText{
    font-weight: bold;
    font-size: 20px;
    font-family: Nunito, sans-serif;
    color: black;
    margin-top: 20px;
}

.active{
    height: 50px;
    margin: 0px;
    text-align: center;
    align-items: center;
    display: flex;
    padding-bottom: 0px;
    padding-top: 0px;
}
