.navBarTitle {
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: 0.4px;
	color: white;
}

.logo{
	width: 55px;
	height: 55px;
	border-radius: 100px;
	background: transparent;
	margin-left: 1px;
}

.navBar {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.navTop {
	background-color: #2d3142;
	color: white;
	height: 56px;
	padding: 20px;
}

.svgIcons {
	width: 28px;
	height: 22px;
	cursor: pointer;
}


.post-add-div{
	border: 1px #E77171 solid;
	border-radius: 5px;
	padding: 5px;
}

.h-menu {
	display: block !important;
}

.drop-down-content{
	width: 180px;
	display: flex;
	text-decoration: none;
	padding: 15px;
	flex-direction: row;
	color: #635b67;
}

.message-icon{
	display: block !important;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
	.h-menu {
		display: block !important;
	}
	.login-reg{
		display: none !important;
	}
	.message-icon{
		display: none !important;
	}
	.dropdown-acc{
		display: none !important;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 768px) {
	.h-menu {
		display: block !important;
	}
	.login-reg{
		display: none !important;
	}
	.message-icon{
		display: none !important;
	}
	.dropdown-acc{
		display: none !important;
	}
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
	.h-menu {
		display: none !important;
	}
	.login-reg{
		display: block !important;
	}
	.message-icon{
		display: block !important;
	}
	.dropdown-acc{
		display: block !important;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
	.h-menu {
		display: none !important;
	}
	.login-reg{
		display: block !important;
	}
	.message-icon{
		display: block !important;
	}
	.dropdown-acc{
		display: block !important;
	}
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.h-menu {
		display: none !important;
	}
	.login-reg{
		display: block !important;
	}
	.message-icon{
		display: block !important;
	}
	.dropdown-acc{
		display: block !important;
	}
}
