.help{
    padding: 30px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active {
    font-family: Nunito, sans-serif;
    padding-bottom: 0px;
    padding-top: 10px;
    padding-left: 20px;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
    font-family: Nunito, sans-serif;
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
    width: 100%;
}

/*.accordion:after {*/
/*    content: '\02795'; !* Unicode character for "plus" sign (+) *!*/
/*    font-size: 13px;*/
/*    color: #777;*/
/*    float: right;*/
/*    margin-left: 5px;*/
/*}*/

/*.active:after {*/
/*    content: "\2796"; !* Unicode character for "minus" sign (-) *!*/
/*}*/
