@import "../../../../src/styles/variables";

.message {

  &-main {
    //min-height: calc(100vh - 56px);
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    color: black;
    margin-top: 80px;
    border: 1px solid lightgrey;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-header {
    height: 64px;
    width: 100%;
    margin-bottom: 10px;
    border-bottom: 1px $off_White solid;
    cursor: pointer;

    &-online{
      font-size: 12px;
    }

    &-image{
      width: 32px;
      height: 32px;
      margin-top: auto;
      margin-bottom: auto;
      border-radius: 50%;
    }

    &-title{
      font-family: Nunito-Bold,sans-serif;
      font-size: 18px;
      font-weight: bold;
      margin-left: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: $body-text;
    }
  }

  &-content-layout {
    width: 100%;
    height: calc(100vh - 56px - 64px - 74px - 20px);
    overflow: auto;
  }

  &-bottom-layout {
    width: calc(100%);
    margin-top: 10px;
    position: relative;
    height: 40px;
    margin-bottom: 10px;
  }

  &-input{
    border-radius: 4px;
    border: solid 1px $very-light-pink;
    position: absolute;
    bottom: 0;
    min-height: 45px;
    padding-top: 8px;
    padding-left: 15px;
    margin-left: 10px;
    margin-right: 10px;
    width:calc(100% - 70px);
    resize: none;
  }

  &-send-button{
    position: absolute;
    right:0;
    bottom: 0;
    margin: 0 10px;
    height: 45px;
    width: 35px;
  }
}

.back-button-container{
  height: 100%;
  width: 30px;
  margin-left: -5px;
  visibility: hidden;
}

.back-button{
  height: 24px;
  width: 21px;
}
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .back-button-container{
    visibility: visible;
    margin:0px 10px;
  }

  .header{

  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 768px) {
  .back-button-container{
    visibility: visible;
    margin: 0px 10px;

  }
}
