.recent-message {
  width: 100%;
  padding: 20px;
  display: flex;


  &-selected{
    border-radius: 4px;
    border-left: 6px #6A3CE8 solid;
    background-color: #efefef;
  }

  &-connection-name{
    height: 22px;
    font-family: Nunito-Bold,sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0px;
    color: #2D3142;
  }

  &-circle{
    width: 10px;
    border-radius: 5px;
    float: right;
    height: 10px;
    background: #6A3CE8;
  }


  &-text{
    height: 22px;
    font-family: Nunito-Regular,sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;

    color: #55595C;

    &-message{
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      margin-right: 4px;
    }

    &-typing{
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      margin-bottom: 0px;
      color: #6A3CE8;
    }

    &-time{
      width: 60px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.circle{
  width: 10px;
  border-radius: 5px;
  float: right;
  height: 10px;
  background: #6A3CE8;
}

.ad-title{
  max-width: 151px;
  overflow: hidden;
  height: 30px;
  background: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
}
