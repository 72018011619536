@import '../../styles/variables';

.sideImg {
  width: 65px;
  height: 65px;
  border-radius: 33px;
  border: 1px solid #e14965;
}

.sideName {
  width: 80%;
  height: 30px;
  font-family: Nunito-Bold, sans-serif;
  font-size: 22px;
  font-stretch: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--white);
}

.sideContent {
  width: 200px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #635b67;
  font-family: Nunito-Regular, sans-serif;
  padding: 10px 0px;
}

.sideContentA {
  width: 200px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #635b67;
  font-family: Nunito-Regular, sans-serif;
  padding: 10px 0px;
}

.sideContentH {
  width: 200px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #635b67;
  font-family: Nunito-Regular, sans-serif;
  padding: 10px 0px;
}

.sideContentActive {
  border-bottom: 4px solid $primary-color;
  width: calc(100% - 200px);
}

.sideContentActiveA {
  border-bottom: 4px solid $primary-color;
  width: 200px;
}

.sideContentActiveH {
  border-bottom: 4px solid $primary-color;
  width: 126px;
}

.sideContentActiveC {
  border-bottom: 4px solid $primary-color;
  width: 102px;
}

.sideContentActiveC {
  border-bottom: 4px solid $primary-color;
  width: 102px;
}

.sideContentActiveM {
  border-bottom: 4px solid $primary-color;
  width: 82px;
}

.sideFooter {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #635b67 !important;
  margin-bottom: 0px;
}

.sideH {
  border-top: 1px solid #4c5156;
  background-color:#635b67 ;
}


.sideTop {
  //margin-top: 75px;
  padding-left: 16px;
  padding-bottom: 15px;
}

.sideBarLogo{
  width:110px;
  height:27px;
  display: none;
  margin-right: 20px;
}
@media (max-width: 575.98px) {
  .sideBarLogo{
    display: inline-block;
  }
}

.sideBar {
  background-color: #f1f1f1;
  padding-top: 15px;
  min-height: 100vh;
  width: 250px;
  height: auto;
  z-index: 3;
}

.sidebarSvg {
  width: 17px;
  height: 27px;
  cursor: pointer;
}

.logout-button{
  margin-top: 20px;
  color: white;
  width: 120px;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  border: 1px white solid;
}
