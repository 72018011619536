@import "variables";

/*Typography classes*/

.textPrimary {
  color: $secondaryColor;
}

.textSecondary {
  color: $off_White;
}

*:focus {
  outline: 0 !important;
}

/*Font classes*/

.NunitoBold {
  font-family: Nunito-Bold, sans-serif;
}

.NunitoRegular {
  font-family: Nunito-Regular, sans-serif;
}

.NunitoSemiBold {
  font-family: Nunito-SemiBold, sans-serif;
}

.NunitoExtraBold {
  font-family: Nunito-ExtraBold, sans-serif;
}
.MerriweatherBold {
  font-family: Merriweather-Bold, sans-serif;
}

/*Background classes*/

.bg-off-white {
  background-color: $off_White;
}

.text-dark {
  color: $body-text;
}
.text-body_text{
  color: $body-text;
}

.text-grey {
  color: $instruction-text;
}

p{
  font-size: 1rem;
}

button{
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  color: white;
  cursor:pointer;
  overflow: hidden;
  outline:none;
}

/**/
h1 {
  font-size: 1.5rem; /*24px*/
}

h2 {
  font-size: 1.25rem; /*20px*/
}

h3 {
  font-size: 1.13rem; /*18px*/
}

h4 {
  font-size: 1rem; /*16px*/
}

h5 {
  font-size: 0.875rem; /*14px*/
}

h6 {
  font-size: 0.75rem; /*12px*/
}

input{
  height: 38px;
  font-size: 1rem;
}

label{
  font-family: Nunito-ExtraBold,sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
}

.label-required{
  font-family: Nunito-Regular,sans-serif;
  font-size: 0.875rem;
  margin-left: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  margin-bottom: 5px;
  letter-spacing: normal;
  text-align: left;
}

.page-heading{
  font-family: Nunito-ExtraBold,sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
}

.connection-name{
  font-family: Nunito-Bold,sans-serif;
  font-size: 1.13rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $body-text;
}

.action-label{
  font-family: Nunito-Regular,sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: $instruction-text;
}

.action-label-mobile{

}


.email-label{
  font-family: Nunito-Regular,sans-serif;
  font-size: 1.13rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $instruction-text;
}

.tab-text{
  font-family: Nunito-Bold,sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color:$body-text;
}

.memory-title{
  width: 198px;
  height: 32px;
  font-family: Merriweather-Bold,serif;
  font-size: 20px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $body-text;
}

.memory-card-image {
  width: 250px;
  height: 161px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
}

.user-name{
  font-family: Nunito-Bold,sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $body-text;
}

.user-avatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.product-avatar{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.tool-tip-container{
  height: 30px !important;
  display: flex !important;
  flex-direction:column !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  font-weight: normal !important;
  background-color: #dddddd !important;
  color: black !important;
}

.user-avatar-small{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.user-avatar-toast{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.user-avatar-collections{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.cancel-image{
  width: 21px;
  height: 25px;
  cursor: pointer;
}

.notification-text{
  font-family: Nunito,sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $body-text;
}

.line{
  background-color: solid 1px var(--very-light-pink);
}

.textSmall {
  font-size: 0.75rem /*12px*/
}

.textMedium {
  font-size: 0.875rem /*14px*/
}

.textLarge {
  font-size: 1rem /*14px*/
}

.storyCardTitle {
  font-size: 1.25rem;
}

.icon{
  width: 18px;
  height: 24px;
}

.navContainer {
  height: 100%;
  min-height: 100vh;
  padding-top: 56px;
  background-color: $off_White;
}

.user-name{
  font-family: Nunito-Bold,sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $body-text;
}

.toggleIcon{
  width:32px;
  height: 29px;
  margin-top: 4px;
  cursor: pointer;
}

.divider {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background-color: white;
}

.h-icon{
  width: 25px;
  height: 15px;
}

.v-icon{
  width: 15px;
  height: 25px;
}

.f-icon{
  width: 20px;
  height: 20px;
}


.spacer {
  flex: 1;
}

.check-box{
  width: 19px;
  height: 22px;
}

.verticalLine{
  width: 1px; /* Line width */
  background-color: #dddddd; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left;
}

.headings{
  font-size: 22px;
  font-family: Merriweather-Bold,sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.main-headings{
  font-size: 18px;
  font-family: Merriweather-Bold,sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.no-data-to-show{
  height: 22px;
  font-family: Nunito-Regular,sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $body-text;
}

.No-results {
  height: 22px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $body-text;
  margin-bottom: 0;
}

.cursor-pointer{
  cursor: pointer;
}

.buttonText {
  cursor: pointer;
}

.toggle-button-arrow {
  width: 24px;
  height: 24px;
  transition: all 0.1s 0.1s;
}

.toggle-up {
  transform: rotate(90deg);
}

.toggle-down {
  transform: rotate(0);
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* and then whatever styles you want*/
  height: 30px;
  width: 100px;
  padding: 5px;
}

/*Toast related css*/

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  mx-height: 38px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-y-logo{
  position: absolute;
  left: calc(50% - 61px);
  top: calc(50% - 12px);
}

@media (max-width: 575.98px) {
  .header-y-logo{
    display: none;
  }
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {

  label .label-required{
    font-size: 0.875rem;
  }

  .page-heading{
    font-size: 1.25rem;
  }

  .connection-name{
    font-size: 1.13rem;
  }

  .email-label{
    font-size: 0.875rem;
  }
  .notification-text{
    font-size: 14px;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 768px) {

  label .label-required{
    font-size: 0.875rem;
  }
  .page-heading{
    font-size: 1.25rem;
  }
  .connection-name{
    font-size: 1.13rem;
  }
  .email-label{
    font-size: 0.875rem;
  }
  .notification-text{
    font-size: 14px;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .user-name {
    font-size: 18px;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .user-name {
    font-size: 18px;
  }
}

