@import "../../../../src/styles/variables";

.messageSearch {
  border: 1px solid red;
  width: 100%;

  &-bodyWrapper {
    width: 100%;
    height: calc(100vh - 56px);
    overflow: auto;
    padding-left: 5px;
    margin-bottom: 10px;
    padding-right: 5px;
  }

  &-input {
    background-color: var(--white);
    border: 1px solid #dddddd;
    width: 100%;
    height: 45px;
    padding: 10px;
  }

  &-content {

  }
  &-email {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    display: block;
    margin: -20px 0 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $instruction-text;

  }

  &-sentTime {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  &-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    float: left;
  }

  &-online {
    width: 15px;
    height: 15px;
    border: solid 2px #ffffff;
    border-radius: 30px;
    margin-left: 36px;
    position: absolute;
    background-color: $shared-collection-color;
  }
}


.chats-heading{
  width: 48px;
  height: 24px;
  font-family: Nunito-Bold,sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--body-text);
}


