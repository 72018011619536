.myAdsWrapper{
    padding-top: 56px;
    height: 100vh;
}

.myAdItem{
    border: 1px solid lightgray;
    margin: 10px;
    transition: transform .2s; /* Animation */
    padding: 10px;
    width: 100%;
}

.myAdItem:hover{
    transform: scale(1.001);
}

.listingButtonEnable{
    border: 1px solid white;
    background: green;
    color: white;
    cursor: pointer;
    /*height: 30px;*/
    padding: 4px 10px;
    border-radius: 5px;
    margin: auto;
    transition: transform .2s; /* Animation */
    font-size: 12px;
    text-align: center;
}

.listingButtonEnable:hover,.listingButtonDisable:hover{
    transform: scale(1.1);
}

.descriptionLayout{
    max-width: 70%;
}
.listingButtonDisable{
    border: 1px solid white;
    background: red;
    padding: 4px 10px;
    transition: transform .2s; /* Animation */
    border-radius: 5px;
    color: white;
    cursor: pointer;
    /*height: 30px;*/
    font-size: 12px;
    margin: auto;
    text-align: center;
}

.previewButton{
    border: 1px solid black;
    padding: 4px 10px;
    transition: transform .2s; /* Animation */
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;
}


.actions{
    width: 160px;
    margin-right: 20px;
}
.edit{
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin: 10px;
    background-color: rgb(95, 123, 128);
    border-radius: 4px;
}

.delete{
    width: 40px;
    height: 30px;
    display: flex;
    margin: 10px;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgb(95, 123, 128);
    border-radius: 4px;
}
