.messagesMainContainer {
    background-color: #f1f1f1;
    height: 100%;
    padding-top: 56px;
    min-height: calc(100vh - 56px);
}

.message-container{
    width: 1000px;
    margin: auto;
}

.recentMessageList{
    max-width: 400px;
}
.chatList{
    max-width: 600px;

}

@media (max-width: 320px) {
    .message-container{
        width: 100%;
        margin: 0;
    }

    .recentMessageList{
        width: 100%;
    }
    .chatList{
        width: 100%;
    }

}

@media (min-width: 321px) and (max-width: 768px) {
    .message-container{
        width: 100%;
        margin: 0;
    }

    .recentMessageList{
        width: 100%;
        max-width: 100%;
    }
    .chatList{
        width: 100%;
    }


}


@media (min-width: 768px) and (max-width: 991.98px) {
    .message-container{
        width: 100%;
        margin: auto;
    }

    .recentMessageList{
        width: 600px;
        max-width: 600px;

    }
    .chatList{
        max-width: 600px;
        width: 100%;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {

    .message-container{
        width: 1000px;
        margin: auto;
    }

    .recentMessageList{
        width: 400px;
        max-width: 400px;
    }
    .chatList {
        width: 600px;
        max-width: 600px;
    }
}

@media (min-width: 1200px) {
    .message-container{
        width: 1000px;
        margin: auto;
    }

    .recentMessageList{
        width: 400px;
        max-width: 400px;
    }
    .chatList{
        width: 600px;
    }

}






