.dashboard_main{
  padding-top: 56px;
  min-height:calc(100vh - 56px);
  height: 100%;
}

.dashboard-recommendations{
  max-width: 1200px;
  padding: 20px;
  height: 100%;
  min-height: 560px;
}

.loadMore{
  width: 100px;
  height: 40px;
  border-radius: 5px;
  background: #6A3CE8;
  color: white;
  margin-bottom: 20px;
}

.footer {
  //position: fixed;
  //left: 0;
  padding: 10px;
  //bottom: 0;
  width: 100%;
  background-color: rgb(95, 123, 128);
  color: white;
  text-align: center;
}

.dashboard{
  min-height: 100%;
  background: transparent;
}
