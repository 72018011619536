.auth_container {
  width: 100%;
  min-height: calc(100vh - 56px);
  height: 100%;
  padding-top: 56px;
  background-color: rgb(248,248,248);
}

.auth_div {
  margin-top: 50px;
  max-width: 800px;
  width:100%;
  height: 520px;
  box-shadow: 0 0 4px rgb(95,123,128);
  background-color: white;
  border-radius: 20px;
}

.left_banner {
  width: 50%;
}

.right_banner{
  width: 50%;
}

.banner-image{
  width: 70%;
  height: 60%;
  //margin-top: 80px;
  //margin: auto;
}

.sign-up-container {
  color: rgb(95,123,128);
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.auth-header-text{
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
  font-family: Nunito-Bold,sans-serif;
}


.auth-inputs-container{
  width: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

hr{
  color: #dddddd;
  margin-top: 6px;
}
.auth-inputs{
  width: 100%;
  padding-left: 5px;
  font-family: Nunito-Regular,sans-serif;
  border-radius: 5px;
  border: 1px solid rgb(95,123,128);
}

.sign-in-with-gmail-container{
  padding-bottom:10px;
  width: 100%;
  border-top: 1px solid rgb(95,123,128);
}
.sign-in-with-gmail-button{
  //border: 1px solid #dddddd;
  //height: 40px;
  //margin-top: 10px;
  //margin-bottom: 10px;
  //width: 100%;
  //border-radius: 5px;
}

.sign-in-with-gmail-button:hover{
  //background-color:rgb(224,115,114);
  color: white;
}

.register-sign-in-button{
  border: 1px solid rgb(224,115,114);
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  color: white;
  background-color: rgb(224,115,114);
  border-radius: 5px;
}

.banner-action{
  color: rgb(95,123,128);
}


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 320px) {
  .left_banner {
    width: 0;
  }

  .banner-action{
    display: none;
  }

  .right_banner{
    width: 100%;
  }

  .banner-action-mobile{
    display: block;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 321px) and (max-width: 799px) {
  .left_banner {
    width: 0;
  }
  .banner-action{
    display: none;
  }

  .right_banner{
    width: 100%;
  }
  .banner-action-mobile{
    display: block;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 800px) and (max-width: 991.98px) {
  .left_banner {
    width: 50%;
  }
  .banner-action{
    display: block;
  }

  .right_banner {
    width: 50%;

  }
  .banner-action-mobile{
    display: none;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .left_banner {
    width: 50%;
  }
  .banner-action{
    display: block;
  }
  .right_banner{
    width: 50%;
  }
  .banner-action-mobile{
    display: none;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .left_banner {
    width: 50%;
  }
  .banner-action{
    display: block;
  }
  .banner-action-mobile{
    display: none;
  }

  .right_banner{
    width: 50%;
  }
}



